<template>
    <swiper
      :pagination="{
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 9
      }"
      :grabCursor="true"
      :lazy="true"
      class="slider"
    >
      <swiper-slide
        v-for="(img, i) in imgs" :key="i"
        class="slider__slide"
      >
        <img :data-src="img" alt="" class="slider__img swiper-lazy">
        <div class="swiper-lazy-preloader"></div>
      </swiper-slide>
    </swiper>
</template>


<script setup>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import SwiperClass, { Navigation, Pagination, Lazy } from "swiper";
  import 'swiper/swiper.scss';
  import 'swiper/components/pagination/pagination.scss';

  SwiperClass.use([Navigation, Pagination, Lazy]);

  defineProps(['imgs'])

</script>


<style lang="scss" scoped>
  @use '@/styles/constants.scss' as *;

  .slider {
    margin: 0;

    &__slide{
      max-width: 100%;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>