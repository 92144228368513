<template>
  <section class="block-contacts contacts">
    <observer @display="waitYmaps"></observer>
    <div class="contacts__map" id="ya-karto"></div>
    <div class="contacts__info">
      <div class="contacts__logos">
        <img class="contacts__logo-dealer" src="../images/logo/rolf-logo.svg" alt="Лого дилера">
        <img class="contacts__logo-hyundai" src="../images/logo/logo.svg" alt="Лого Hyundai">
      </div>
      <div class="contacts__address">{{ address }}</div>
      <div class="contacts__worktime">{{ worktime }}</div>
      <a @click="sendMetric('click_phone')" class="contacts__phone" :href="'tel:' + phone_raw">{{ phone }}</a>

      <callback-input 
        v-on="$attrs" 
        @callBack="callBack"
        :data="{form:'contacts', type:'sale'}"
      >Оставить заявку</callback-input>
    </div>
  </section>
</template>

<script>
import Mask from "../common/mask";
import Mixin from "../common/mixin";
import Keyup from "../common/keyup";
import Observer from "./observer.vue";
import placemarkImg from "../images/utils/placemark.png";
import CONSTANTS from "../common/constants";
import CallbackInput from "./callback-form/callback-input.vue";
import sendMetric from "@/common/sendMetric";

export default {
  name: "block-contacts",
  components: { Observer, CallbackInput },
  directives: { Mask, Keyup },
  mixins: [Mixin],
  data() {
    return {
      worktime: CONSTANTS.worktime,
      address: CONSTANTS.offices[0].address,
      phone: CONSTANTS.phone,
      phone_raw: CONSTANTS.phone_raw
    };
  },
  mounted() {
    // this.$nextTick(this.waitYmaps);
  },
  methods: {
    callBack(e){
      this.$emit('callBack', e);
    },
    initYandexMap() {
      const isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
          return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
          );
        },
      };

      let zoomControl = new ymaps.control.ZoomControl({
        options: {
          position: {
            left: "auto",
            right: 9,
            top: 108,
          },
        },
      });

      let center_x = this.CONSTANTS.coords.x_center;
      let center_y = this.CONSTANTS.coords.y_center;

      let myMap = new ymaps.Map(
        "ya-karto",
        {
          center: [center_y, center_x],
          zoom: 13,
          controls: [],
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      if (!isMobile.any()) {
        myMap.controls.add(zoomControl);
      }

      this.CONSTANTS.offices.forEach((point, i) => {
        myMap.geoObjects.add(
          new ymaps.Placemark(
            [point.y_coords, point.x_coords],
            {
              iconContent: point.address,
              balloonContent: `<div>${point.address}<br/>${point.time}</div>`
            },
            {
              iconLayout: "default#image",
              iconImageHref: placemarkImg,
              iconImageSize: [40, 60],
              // iconImageOffset: [-40, -24],
              // iconContentOffset: [45, 3],
            }
          )
        );
      });

      // if (isMobile.any() || this.device_platform !== "desktop") {
      //   myMap.behaviors.disable("drag");
      // }
      myMap.behaviors.disable("scrollZoom");
    },
    waitYmaps() {
      let script = document.createElement("script");
      script.src = "//api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU";
      document.head.appendChild(script);
      script.onload = () => {
        ymaps.ready(this.initYandexMap);
      };
    },
    sendMetric,
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.contacts {
  position: relative;
  &__map {
    width: 100%;
    height: 400px;
    box-shadow: 0 0 12px 0 hsla(0, 0%, 77%, 0.5);
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 20px;
    left: 150px;
    z-index: 10;
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 12px 0 hsla(0, 0%, 77%, 0.5);
  }
  &__logos {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  &__logo-dealer {
    width: 150px;
    margin-right: 15px;
  }
  &__worktime {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__phone {
    display: flex;
    margin-bottom: 20px;
    color: #002c5f;
    font-weight: bold;
    font-size: 20px;
  }
  &__address {
    font-weight: 500;
    margin: 7px 0;
  }
}

@media (max-width: 640px) {
  .contacts {
    &__map {
      height: 248px;
    }
    &__info {
      position: static;
      align-items: center;
    }
    &__logos {
      width: 100%;
      flex-direction: column;
    }
    &__logo-dealer {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
