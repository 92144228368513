import CF from "./common-functions";
import models from "../common/cars-info"
const PHONE = '+7 (495) 755-88-44'.replace(/-/g, '\u2011');

const PHONE_RAW = PHONE.replace(/\D+/g, "");
let start = new Date(2024, 4, 16, 23, 0, 0);
let next = CF.getNextDate(start);

function loadMapIcon(number) {
    return require(`../images/map${number}.png`)
}

export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    worktime: "Ежедневно с 8:00 до 22:00",
    offices: [
        {
            name: "РОЛЬФ Алтуфьево",
            address: "г. Москва, Алтуфьевское ш., д. 31, корп. 1",
            x_coords: 37.582942,
            y_coords: 55.862641,
            icon: loadMapIcon('1')
        }
    ],
    coords: {
        x_center: 37.582942,
        y_center: 55.862641,
    },
    need_agreement: true,
    cKeeper: "ctw",
    models: models.CARS.map(el => el.folder_name),
    start_date: start,
    end_date: next,
}
