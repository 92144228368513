<template>
  <block-header @="block_handlers"></block-header>
  <block-main @="block_handlers"></block-main>
  <block-special @="block_handlers"></block-special>
  <block-cars @setStockActive="setStockActive" @="block_handlers"></block-cars>
	<block-stock :stock_active="stock_active" @="block_handlers"></block-stock>
	<block-corporate @="block_handlers"></block-corporate>
  <block-traid-in @="block_handlers"> </block-traid-in>
  <block-benefit @="block_handlers"></block-benefit>
  <block-advantages @="block_handlers"></block-advantages>
  <block-warinty @="block_handlers"></block-warinty>
  <block-contacts @="block_handlers"></block-contacts>
	<block-footer> </block-footer>


  <transition name="fade">
    <full-get
      :data="data"
      @close="closeThings"
      v-on="block_handlers"
      v-show="show.get"
    />
  </transition>
  <transition name="fade">
    <full-info
      @close="closeThings"
      v-on="block_handlers"
      v-show="show.info"
    />
  </transition>

  	<transition name="fade">
			<full-agreement @close="closeAgreement" v-show="show.agreement" />
		</transition>
		<transition name="fade">
			<full-thanks @close="closeThings" v-show="show.thanks" />
		</transition>
		<transition name="fade">
			<full-slider
				:slides="slides"
				:activeIndex="activeIndex"
				@close="closeThings"
				v-if="show.slider"
			/>
		</transition>
</template>

<script>
import BlockHeader from "./components/block-header.vue";
import BlockMain from "./components/block-main.vue";
import BlockSpecial from "./components/block-special/block-special.vue";
import BlockCars from "./components/block-cars/block-cars.vue";
import BlockStock from "./components/block-stock.vue";
import BlockCorporate from './components/block-corporate.vue';
import BlockTraidIn from "./components/block-traid-in.vue";
import BlockBenefit from "./components/block-benefit.vue";
import BlockAdvantages from "./components/block-advantages.vue";
import BlockWarinty from "./components/block-warinty.vue"
import BlockContacts from "./components/block-contacts.vue"
import BlockFooter from "./components/block-footer.vue"
import FullSlider from "./components/full-slider.vue";
import FullThanks from "./components/full-thanks.vue";
import FullAgreement from "./components/full-agreement.vue";
import FullGet from "./components/full-get.vue";

import Send from "./common/send";
import Mixin from "./common/mixin";
import sendMetric from './common/sendMetric.js'

export default {
  mixins:[Mixin,Send],
  name: "App",
  components: {
    BlockHeader,
    BlockMain,
    BlockSpecial,
		BlockFooter,
    BlockCars,
		BlockStock,
		BlockCorporate,
    BlockTraidIn,
    BlockBenefit,
    BlockAdvantages,
    FullGet,
    FullAgreement,
    BlockWarinty,
    BlockContacts,
		FullSlider,
	FullThanks,
  },
  data() {
    return {
      show: {
        agreement: false,
        get: false,
        thanks: false,
        coupon: false,
        load: false,
        lightbox: false,
				slider: false,
      },
      data: {},
			slides: [],
			activeIndex: 0,
			stock_active: 'Palisade'
    };
  },
  computed: {
		block_handlers() {
			return {
				getAgreement: this.getAgreement,
				callBack: this.callThere,
				getCall: this.handleGetCall,
				getSlider: this.handleSlider,
			};
		},
	},
  methods:{
    callThere(event) {
			let cKeeper = this.CONSTANTS.cKeeper,
				manager_phone = this.CONSTANTS.need_manager_phone
					? document.getElementById("phone")
							.text()
							.replace(/\s/g, "\u00A0")
							.replace(/-/g, "\u2011")
							.replace(/\D+/g, "")
					: "",
				req = Send(event, this.data, cKeeper, manager_phone);
			this.show.load = true;

			if (cKeeper === "actioncall") {
				const finishCall = () => {
					this.closeThings();
					this.show.load = false;
					this.show.thanks = true;
					if (typeof window.dataLayer !== "undefined") {
						window.dataLayer.push({
							event: "callkeeper-call_order-ckaction",
							eventCategory: "callkeeper",
							eventAction: "call_order",
							eventLabel: "ckaction",
						});
					}
					console.log("done");
				};

				if (!req) {
					finishCall();
				} else {
					req.finally(finishCall);
				}
			} else {
				this.closeThings();
				this.show.load = false;
				this.show.thanks = true;
			}
		},
		handleSlider({ slides, activeIndex }) {
			this.slides = slides;
			this.activeIndex = activeIndex;
			this.show.slider = true;
			this.blockScroll();
		},
    sendMetric,
    handleGetCall(data) {
			this.show.get = true;
			this.data = data;
			this.blockScroll();
      this.sendMetric('open_form') // Отправка даннх в метрику
    },
    closeThings() {
			this.show.get = false;
			this.show.thanks = false;
			this.show.agreement = false;
			this.show.coupon = false;
			this.show.lightbox = false;
			this.show.slider = false;
			this.data = {};
			this.slides = [];
			this.activeIndex = 0;
			this.unblockScroll();
		},
    getAgreement() {
			this.show.agreement = true;
			this.blockScroll();
		},
    closeAgreement() {
			this.show.agreement = false;
			if (!this.show.get) {
				this.unblockScroll();
			}
		},
		setStockActive(car) {
			this.stock_active = car;
		}
  },
};
</script>

<style lang="scss">

</style>
